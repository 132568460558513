import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PreferencesState {
    dark_mode: boolean
}
const initialState: PreferencesState = {
    dark_mode: false
}

export const preferencesAlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        putPreferences: (state, action: PayloadAction<PreferencesState>) => {
            return action.payload
        },
        reset: (state) => {
            return initialState
        }
    },
})

export const { putPreferences, reset } = preferencesAlice.actions;
export const selectPreferences = (state: RootState) => state.preferences
export const selectPreferredDarkness = (state: RootState) => state.preferences.dark_mode

export default preferencesAlice.reducer