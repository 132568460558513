export interface ITimeService {
    getDailyQuizId() : string
}

export class TimeService implements ITimeService {
    getDailyQuizId(date: Date = new Date(Date.now())): string {
        let id = ''

        const day = date.getUTCDate()
        const month = date.getUTCMonth()
        const year = date.getUTCFullYear()
        const time = TimeService.getUTC24HourTime(date)
        
        // 0500 UTC is 0000 EST 
        const isPastMidnightEST = time >= 500  
        
        id = Intl.DateTimeFormat('en-US', { month: "short", day: "numeric", year: "numeric"})
        .format(
            new Date(
                year,
                month,
                isPastMidnightEST ? day : day - 1
            )
        )
        return id
    }

    static pad = (t: string) : string => {
        if (t.length === 0) return "00" 
        return t.length === 1 ? `0${t}`: t
    }

    static getUTC24HourTime = (date: Date = new Date(Date.now())) : number => {
        const hours = date.getUTCHours()
        const minutes = date.getUTCMinutes()
        const paddedMinutes = TimeService.pad(minutes.toString())
        const hours24 = `${hours}${paddedMinutes}`
        return parseInt(hours24)
    }
}