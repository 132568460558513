import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReadQuiz } from 'dto/quiz/types';

const initialState: IReadQuiz = {
    id: '',
    type: '',
    total_questions: 0,
    questions: []
}

export const cachedQuizSlice = createSlice({
    name: 'cached_quiz',
    initialState,
    reducers: {
        setCachedQuiz: (state, action: PayloadAction<IReadQuiz>) => {
            return action.payload
        },
        reset: (state) => {
            return initialState
        }
    },
})

export const { setCachedQuiz, reset } = cachedQuizSlice.actions;
export const selectCachedQuiz = (state: RootState) => state.cached_quiz

export default cachedQuizSlice.reducer