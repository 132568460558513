import React, { useState } from "react"
import { Button } from "@mui/material"
import ShareIcon from '@mui/icons-material/Share';

interface ShareResultsButtonProps {
    title: string
    url: string
    text: string
}

export const ShareResultsButton = ({ title, url, text}: ShareResultsButtonProps) => {
    const [innerText, setInnerText] = useState<string>("Share Results")

    const handleShare = () => {
        // @ts-ignore
        if (navigator.share) {
            return navigator.share({
                title,
                url,
                text
            })
        } 
        
        navigator.clipboard.writeText(`${title}\n${text}\n${url}`)
        setInnerText("Copied to Clipboard!")

        setTimeout(() => {
            setInnerText("Share Results")
        }, 2500)
    }

    return (
        <Button onClick={handleShare} variant="contained" endIcon={<ShareIcon />} sx={{width: "75%"}}>
            {innerText}
        </Button>
    )
}