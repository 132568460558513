import React from 'react';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { HeaderMenu } from './HeaderMenu';
import { TimeService } from 'services/time';

export const Header = () => {
    return (
        <Box sx={{ flexGrow: 1, mb: "2rem" }}>
            <AppBar position="static">
                <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Quizche
                    </Typography>
                    <Typography mr="0.75rem">
                        { new TimeService().getDailyQuizId() }
                    </Typography>
                    <HeaderMenu />
                </Toolbar>
            </AppBar>
        </Box>
    )
}