import { Typography } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { IActiveQuizResponse } from "dto/quiz/types"
import React from "react"

interface QuizProgressBoxProps {
    response: IActiveQuizResponse
    activeQuestionIdx: number
}

const determineBackgroundColor = (response: IActiveQuizResponse, activeQuestionIdx: number) => {
    if (response.answered) {
        return response.correct ? "#4CAF50" : "#F44336"
    } else {
        return response.question_index === activeQuestionIdx ? "#7e57c2" : "#c0c0c0"
    }
}

export const QuizProgressBox = ({response, activeQuestionIdx} : QuizProgressBoxProps) => {
    const bgColor = determineBackgroundColor(response, activeQuestionIdx)
    const textColor = bgColor === "#c0c0c0" ? "#000000" : "#ffffff"

    return (
        <Grid
            key={`pb-${response.question_index}`}
            sx={{ backgroundColor: bgColor }}
            xs={0.75}
            sm={0.75}
            md={0.75}
            lg={0.75}
            xl={0.75}
            height="100%"
            borderRadius="0.25rem"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Typography sx={{color: textColor}}>{ response.question_index + 1}</Typography>
        </Grid>
    )
}