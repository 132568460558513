import { RootState } from '../../app/store';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReadQuiz } from 'dto/quiz/types';
import { getQuiz } from 'clients/api/quizche';
import { TimeService } from 'services/time';

export const fetchDailyQuiz = createAsyncThunk(
    'active_quiz/fetchDailyQuiz',
    async (cachedQuiz : IReadQuiz, thunkApi) => {
        const todaysQuizId = new TimeService().getDailyQuizId()

        if (cachedQuiz.id !== todaysQuizId) {
            try {
                let res = await getQuiz('daily')
                return res.data
            } catch (e) {
                console.log(e)
            }
        }

        return cachedQuiz
    }
)

interface ActiveQuiz {
    loading: boolean
    quiz: IReadQuiz
}

const initialState: ActiveQuiz = {
    loading: true,
    quiz: {
        id: '',
        type: '',
        total_questions: 0,
        questions: []
    }
}

export const activeQuizSlice = createSlice({
    name: 'active_quiz',
    initialState,
    reducers: {
        setActiveQuiz: (state, action: PayloadAction<IReadQuiz>) => {
            return { loading: false, quiz: action.payload }
        },
        reset: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDailyQuiz.fulfilled, (state, action) => {
            return { loading: false, quiz: action.payload }
        })
        builder.addCase(fetchDailyQuiz.pending, (state) => {
            return { loading: true, quiz: state.quiz }
        })
    },
})

export const { setActiveQuiz, reset } = activeQuizSlice.actions;
export const selectActiveQuiz = (state: RootState) => state.active_quiz

export default activeQuizSlice.reducer