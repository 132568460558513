import React from "react";
import { Tooltip, Typography } from "@mui/material";
import info from "../../build.json"

export const BuildInfo = () => {
    return (
        <Tooltip title={`${info.buildTime}`} placement="bottom">
            <Typography>v{info.version}-{info.build}</Typography>
        </Tooltip>
    )
}