import React from "react";
import { Container, Typography } from "@mui/material";
import { IReadQuiz } from "dto/quiz/types";
import { decode } from 'html-entities';
import { selectActiveQuizResult } from "features/quiz/activeQuizResultSlice";
import { useSelector } from "react-redux";
import { ResponseDisplay } from "./ResponseDisplay";

interface QuestionDisplayProps {
    quiz: IReadQuiz
}

export const QuestionDisplay = ({ quiz } : QuestionDisplayProps) => {
    const activeQuizResult = useSelector(selectActiveQuizResult)
    const currentQuestion = quiz.questions[activeQuizResult.total_responses]

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>{ decode(currentQuestion.question) }</Typography>
            <ResponseDisplay 
                question={currentQuestion} 
                questionNumber={activeQuizResult.total_responses + 1} 
                totalQuestions={quiz.total_questions} 
            />
        </Container>
    )
}