import React from 'react';
import { Box, Container } from '@mui/material';
import { Quiz } from 'components/quiz/quiz';
import { QuizHistoryDisplay } from 'components/quiz/history/QuizHistory';
import { useSelector } from 'react-redux';
import { selectDisplayValue } from 'features/display_router/displayRouterSlice';

const determineDisplay = (value: number) => {
  switch(value) {
    case 0:
      return <QuizHistoryDisplay />
    case 1:
      return <Quiz />
    // case 2:
    //   return <Leaderboard />
    default:
      return <Quiz />
  }
}

export function Dashboard() {
  const value = useSelector(selectDisplayValue)

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      > 
        { determineDisplay(value) }
      </Box>
    </Container>
  );
}
