import { createTheme, ThemeOptions,responsiveFontSizes} from "@mui/material";

export const light : ThemeOptions = responsiveFontSizes(createTheme({
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#7e57c2',
      },
      secondary: {
        main: '#4caf50',
      }
    },
}))