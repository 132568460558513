import { TimeParts } from "./date/types"

export const getTodaysQuizId = () : string => {
    let id : string = "Nov 6 '22"
    return id
}

export const formatDurationTime = (duration: number) : string => {
    let display = ''

    let { hours, minutes, seconds, millis } = getTimeParts(duration)

    if (hours > 0) { display += pad(hours.toString()) + ":" }
    if (minutes > 0) {display += pad(minutes.toString()) + ":"}
    display += pad(seconds.toString()) + "." 
    display += millis // string + int = string 

    return display
}

const getTimeParts = (milliseconds: number) : TimeParts => {
    let timeParts = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        millis: 0,
    }

    let milliString = milliseconds.toString()

    timeParts.hours = Math.floor(milliseconds / (1000 * 60* 60))
    timeParts.minutes = Math.floor( milliseconds / (1000 * 60) % 60)
    timeParts.seconds = Math.floor((milliseconds / 1000) % 60)
    timeParts.millis = parseInt(milliString.slice(milliString.length - 3))

    return timeParts
}

export const pad = (t: string) : string => {
    if (t.length === 0) return "00" 
    return t.length === 1 ? `0${t}`: t
}