import React from "react";
import { IReadQuiz } from "dto/quiz/types";
import { selectActiveQuizResult } from "features/quiz/activeQuizResultSlice";
import { useSelector } from "react-redux";
import { QuizResultDisplay } from 'components/quiz/results/QuizResultsDisplay';
import { QuestionDisplay } from "./QuestionDisplay";
import { QuizProgress } from "components/quiz/progress/Progress";
import { Stack } from "@mui/material";

interface ActiveQuizDiplayProps {
    quiz: IReadQuiz
}

export const ActiveQuizDisplay = ({ quiz } : ActiveQuizDiplayProps) => {
    const activeQuizResult = useSelector(selectActiveQuizResult)

    if (activeQuizResult.completed) return (<QuizResultDisplay />)
    return (
        <Stack width="100%">
            <QuizProgress />
            <QuestionDisplay quiz={quiz} />
        </Stack>
    )
}