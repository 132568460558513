import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material"
import HistoryIcon from '@mui/icons-material/History';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import QuizIcon from '@mui/icons-material/Quiz';
import { useSelector } from 'react-redux';
import { setDisplayValue, selectDisplayValue } from 'features/display_router/displayRouterSlice';
import { useAppDispatch } from 'app/hooks';


export const Footer = () => {
  const dispatch = useAppDispatch()
  const value = useSelector(selectDisplayValue)

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={8}>    
      <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
              dispatch(setDisplayValue({ value: newValue }));
          }}
      >         
        <BottomNavigationAction label="History" icon={<HistoryIcon/>}/>
        <BottomNavigationAction label="Quiz" icon={<QuizIcon/>}/>
      </BottomNavigation>
    </Paper>
  );
}

{/* <BottomNavigationAction label="Leaderboard" icon={<LeaderboardIcon/>}/> */}