import React from "react";
import { Stack, Switch } from "@mui/material";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useSelector } from "react-redux";
import { useAppDispatch } from "app/hooks";
import { selectPreferredDarkness, putPreferences } from "features/preferences/preferencesSlice";

export const DarkModeToggle = () => {
    const dispatch = useAppDispatch()
    const darkMode = useSelector(selectPreferredDarkness)

    const handleChange = () => {
        dispatch(putPreferences({ dark_mode: !darkMode }))
    }
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <LightModeIcon />
            <Switch checked={darkMode} onChange={handleChange} />
            <DarkModeIcon />
        </Stack>
    )
}