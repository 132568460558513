import axios from "axios"
import { IQuizResult } from "dto/quiz/types";

let baseURL : string
const environment = process.env.REACT_APP_WEB_ENV || ''

switch (environment.toUpperCase()) {
    case "LOCAL":
        baseURL = "http://localhost:8080"
        console.log("RUNNING IN LOCAL MODE")
        break;
    case "DEVELOP":
        baseURL = "https://dev.quizche.com"
        console.log("RUNNING IN DEV MODE")
        break;
    default:
        baseURL = "https://api.quizche.com"
}

const api = axios.create({
    baseURL,
    timeout: 5000
})

export const getQuiz = async (id: string = '') => {
    return await api.get(`/v1/quiz/${id}`)
}

export const persistQuizResult = async (result: IQuizResult) => {
    return await api.post(`/v1/results`)
}