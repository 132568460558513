import React, { useState } from "react";
import { decode } from 'html-entities';
import { Button, Typography, Stack } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { IQuizResult, IQuizQuestion, QuizHistory } from "dto/quiz/types";
import { patchActiveQuizResult, selectActiveQuizResult } from "features/quiz/activeQuizResultSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from 'app/hooks';
import { shuffle } from "functions/array";
import { setCachedQuiz } from "features/quiz/cachedQuizSlice";
import { selectActiveQuiz } from "features/quiz/activeQuizSlice";
import { putQuizHistory, selectQuizHistory } from "features/quiz/quizHistorySlice";
import { QuestionMarkSharp } from "@mui/icons-material";
import { Box } from "@mui/system";

interface ResponseDisplayProps {
    question: IQuizQuestion
    questionNumber: number
    totalQuestions: number
}

export const ResponseDisplay = ({ question, questionNumber, totalQuestions } : ResponseDisplayProps) => {
    const dispatch = useAppDispatch()
    const quiz = useSelector(selectActiveQuiz)
    const activeQuizResult = useSelector(selectActiveQuizResult)
    const quizHistory = useSelector(selectQuizHistory)
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

    // @ts-ignore
    const handleAnswerSelect = (e) => {
        setSelectedIndex(null)

        // @ts-ignore
        let selection = question.responses[selectedIndex]
        let isCorrect = selection === decode(question.correct_response)
        let isLastQuestion = questionNumber === totalQuestions

        let patchResult = { ...activeQuizResult }
        
        let responses = activeQuizResult.responses.slice()
        let responseUpdate = {...responses[activeQuizResult.total_responses]}
        responseUpdate.answered = true
        responseUpdate.correct = isCorrect
        responses[activeQuizResult.total_responses] = responseUpdate

        patchResult.total_responses = activeQuizResult.total_responses + 1
        patchResult.responses = responses
        
        if (isCorrect) {
            patchResult["number_correct"] = activeQuizResult.number_correct + 1
        } else {
            patchResult["number_incorrect"] = activeQuizResult.number_incorrect + 1
        }

        if (isLastQuestion) {
            // set patch end values
            let end_time = Date.now()
            patchResult.completed = true
            patchResult.end_time = end_time
            patchResult.duration = end_time - activeQuizResult.start_time

            // cache quiz after it is completed
            dispatch(setCachedQuiz(quiz.quiz))

            // update quiz history
            let newHistory: QuizHistory = {
                // @ts-ignore
                correct_responses_count: quizHistory.correct_responses_count + patchResult.number_correct,
                // @ts-ignore
                total_response_count: quizHistory.total_response_count + patchResult.total_responses,
                total_time_sum: quizHistory.total_time_sum + patchResult.duration,
                completed_quiz_count: quizHistory.completed_quiz_count + 1
            }
            
            dispatch(putQuizHistory(newHistory))
        }


        dispatch(patchActiveQuizResult(patchResult))
    }

    return (
        <>
            <Grid container spacing={2} columns={2} mt="1rem" width="100%">
                {
                    question.responses.map((v, i) => (
                        
                        <Grid 
                            onClick={(e) => { 
                                setSelectedIndex(i)
                            }} 
                            key={`q${questionNumber}-r${i}`}
                            xs={question.responses.length === 2 ? 2 : 1}
                            sx={{ cursor: "pointer"}}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center" 
                                sx={{ textAlign: "center", width: "100%", height: "5rem", border: 2, borderColor: "#7e57c2", borderRadius: "0.75rem", backgroundColor: `${selectedIndex === i ? "#c0c0c0" : "inherit"}` }}>
                                <Typography color="primary">
                                    {decode(v)}
                                </Typography>
                            </Box>
                            
                        </Grid>
                    ))
                }
            </Grid>

            <Button 
                onClick={handleAnswerSelect}
                disabled={selectedIndex === null} 
                variant="contained"
                sx={{width: "100%", mt: "2rem"}}
            >
                Submit
            </Button>
        </>
    )
}