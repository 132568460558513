import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQuizResult } from 'dto/quiz/types';

const initialState: IQuizResult = {
    user_id: '',
    quiz_id: '',
    completed: false,
    start_time: 0,
    end_time: 0,
    duration: 0,
    total_questions: 0,
    total_responses: 0,
    number_correct: 0,
    number_incorrect: 0,
    responses: []
}

export const activeQuizResult = createSlice({
    name: 'active_quiz_result',
    initialState,
    reducers: {
        patchActiveQuizResult: (state, action: PayloadAction<Partial<IQuizResult>>) => {
            return { ...state, ...action.payload }
        },
        reset: () => {
            return initialState
        }
    }
})

export const { patchActiveQuizResult, reset } = activeQuizResult.actions;
export const selectActiveQuizResult = (state: RootState) => state.active_quiz_result

export default activeQuizResult.reducer