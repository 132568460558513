import React, { useEffect } from 'react';
import { IQuizResult } from 'dto/quiz/types';
import { useSelector } from "react-redux";
import { useAppDispatch } from 'app/hooks';
import { patchActiveQuizResult, selectActiveQuizResult } from "features/quiz/activeQuizResultSlice";
import { selectActiveQuiz, fetchDailyQuiz } from 'features/quiz/activeQuizSlice';
import { ActiveQuizDisplay } from 'components/quiz/active/ActiveQuizDisplay';
import { Button, CircularProgress } from '@mui/material';
import { selectCachedQuiz } from 'features/quiz/cachedQuizSlice';

export const Quiz = () => {
    const dispatch = useAppDispatch()
    const cachedQuiz = useSelector(selectCachedQuiz)
    const { loading, quiz } = useSelector(selectActiveQuiz)
    const activeQuizResult = useSelector(selectActiveQuizResult)
    const inProgress = !loading && activeQuizResult.quiz_id === quiz.id && !activeQuizResult.completed
    const newQuiz = !loading && !inProgress && quiz.id !== cachedQuiz.id

    useEffect(() => {
        dispatch(fetchDailyQuiz(cachedQuiz))
    }, [])

    // @ts-ignore
    const handleStart = (e) => {
        e.preventDefault()
        let patchResult : Partial<IQuizResult> = {
            user_id: '',
            quiz_id: quiz.id, 
            completed: false,
            start_time: Date.now(),
            end_time: 0,
            duration: 0,
            total_questions: quiz.total_questions,
            total_responses: 0,
            number_correct: 0,
            number_incorrect: 0,
            responses: quiz.questions.map((q, idx) => {
                return {
                    question_index: idx,
                    answered: false,
                    correct: false
                }
            }) 
        }
        dispatch(patchActiveQuizResult(patchResult))
    }

    if (loading) return <CircularProgress />
    if (newQuiz) return <Button variant="contained" sx={{ width: "10rem", height: "5rem", mt: "5rem"}} onClick={handleStart}>Start Quiz</Button>
    return <ActiveQuizDisplay quiz={quiz} /> 
}