import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface DisplayRouterState {
    value: number
}
const initialState: DisplayRouterState = {
    value: 1
}


export const displayRouterSlice = createSlice({
    name: 'display_router',
    initialState,
    reducers: {
        setDisplayValue: (state, action: PayloadAction<DisplayRouterState>) => {
            return action.payload
        }
    },
})

export const { setDisplayValue } = displayRouterSlice.actions;
export const selectDisplayValue = (state: RootState) => state.display_router.value

export default displayRouterSlice.reducer