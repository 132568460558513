import React from 'react';
import { Stack, Typography } from "@mui/material"
import { selectQuizHistory } from "features/quiz/quizHistorySlice"
import { formatDurationTime } from "functions/date"
import { useSelector } from "react-redux"

export const QuizHistoryDisplay = () => {
    const quizHistory = useSelector(selectQuizHistory)
    const averageScore = ((quizHistory.correct_responses_count / quizHistory.total_response_count) * 10)
    const averageTime = quizHistory.total_time_sum / quizHistory.completed_quiz_count

    return (
        <Stack>
            <Typography variant="h4" textAlign="center" mb="1rem">History</Typography>
            <Typography>Completed quizzes: { quizHistory.completed_quiz_count }</Typography>
            <Typography>Total quiz time: { formatDurationTime(quizHistory.total_time_sum) }</Typography>
            <Typography>Total questions answered: { quizHistory.total_response_count } </Typography>
            <Typography>Total correct responses: { quizHistory.correct_responses_count }</Typography>
            <Typography>Average quiz score: { isNaN(averageScore) ? 0 : averageScore.toFixed(2) }</Typography>
            <Typography>Average quiz time: { isNaN(averageTime) ? 0 : formatDurationTime(averageTime) }</Typography>
        </Stack>
    )
}