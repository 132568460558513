import React from "react";
import { useSelector } from "react-redux";
import { selectActiveQuizResult } from "features/quiz/activeQuizResultSlice";
import { Box, Stack, Typography } from "@mui/material";
import { formatDurationTime } from "functions/date";
import { IActiveQuizResponse } from "dto/quiz/types";
import { QuizProgress } from "components/quiz/progress/Progress";
import { ShareResultsButton } from "components/share_results/ShareResultsButton";

export const QuizResultDisplay = () => {
    const activeQuizResult = useSelector(selectActiveQuizResult)
    const responseOverview = `✅: ${activeQuizResult.number_correct} of ${activeQuizResult.total_responses}`
    const responseTime = `⏱️: ${formatDurationTime(activeQuizResult.duration)}`
    const responseBreakdown = activeQuizResult.responses.map((r : IActiveQuizResponse, idx) => {
        return r.correct ? "🟩" : "🟥"
    }).join(" ")
    
    return (
        <Stack width="100%" alignItems="center">
            <QuizProgress />

            <Stack
                display="flex"
                alignItems="center"
                mt="1rem"
                mb="2rem"
            >
                <Box>
                    <Typography>Quizche {activeQuizResult.quiz_id}</Typography>
                    <Typography>{responseOverview}</Typography>
                    <Typography>{responseTime}</Typography>
                    <Typography>{responseBreakdown}</Typography>
                    <Typography>https://quizche.com</Typography>
                </Box>
            </Stack>

            <ShareResultsButton 
                title={`Quizche ${activeQuizResult.quiz_id}`}
                url="https://quizche.com"
                text={`Quizche ${activeQuizResult.quiz_id}\n${responseOverview}\n${responseTime}\n${responseBreakdown}`}
            />
            
        </Stack>
        
    )
}