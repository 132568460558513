import { RootState } from '../../app/store';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuizHistory } from 'dto/quiz/types';

const initialState: QuizHistory = {
    correct_responses_count: 0,
    total_response_count: 0,
    total_time_sum: 0,
    completed_quiz_count: 0
}

export const quizHistorySlice = createSlice({
    name: 'quiz_history',
    initialState,
    reducers: {
        putQuizHistory: (state, action: PayloadAction<QuizHistory>) => {
            return action.payload
        },
        reset: (state) => {
            return initialState
        }
    },
})

export const { putQuizHistory, reset } = quizHistorySlice.actions;
export const selectQuizHistory = (state: RootState) => state.quiz_history

export default quizHistorySlice.reducer