import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material';
import { Footer } from 'components/footer/Footer';
import { Header } from 'components/header/Header';
import { useSelector } from 'react-redux';
import { selectPreferredDarkness } from 'features/preferences/preferencesSlice';
import { light } from 'theme/light';
import { dark } from 'theme/dark';
import { Dashboard } from 'pages/Dashboard';

export default function App() {
  const darkMode = useSelector(selectPreferredDarkness)

  return (
    <ThemeProvider theme={darkMode ? dark : light}>
      <CssBaseline enableColorScheme />
      <Header />
      <Dashboard />
      <Footer />
    </ThemeProvider>
   );
}

