import React from 'react'
import { selectActiveQuizResult } from 'features/quiz/activeQuizResultSlice'
import { useSelector } from 'react-redux'
// import { Grid } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import { QuizProgressBox } from './ProgressBox'

interface QuizProgressProps {

}

export const QuizProgress = ({} : QuizProgressProps) => {
    const activeQuizResult = useSelector(selectActiveQuizResult)

    return (
        <Grid 
            container 
            width="100%"
            direction="row"
            columns={10} 
            justifyContent="space-evenly"
            spacing={1}
            mb="1rem"
        >
            {
                activeQuizResult.responses.map((r, idx) => (
                    <QuizProgressBox 
                        key={`${activeQuizResult.quiz_id}-prog-${idx}`}
                        response={r} 
                        activeQuestionIdx={activeQuizResult.total_responses}
                    />
                ))
            }
        </Grid>
    )
}