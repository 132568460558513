import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { loadState, saveState } from 'localStorage';
import activeQuizSlice from 'features/quiz/activeQuizSlice';
import activeQuizResultSlice from 'features/quiz/activeQuizResultSlice';
import cachedQuizSlice from 'features/quiz/cachedQuizSlice';
import quizHistorySlice from 'features/quiz/quizHistorySlice';
import preferencesSlice from 'features/preferences/preferencesSlice';
import displayRouterSlice from 'features/display_router/displayRouterSlice';


export const store = configureStore({
  reducer: {
    active_quiz: activeQuizSlice,
    active_quiz_result: activeQuizResultSlice,
    cached_quiz: cachedQuizSlice,
    quiz_history: quizHistorySlice,
    preferences: preferencesSlice,
    display_router: displayRouterSlice
  },
  preloadedState: loadState()
});

store.subscribe(() => {
  saveState({
    active_quiz_result: store.getState().active_quiz_result,
    cached_quiz: store.getState().cached_quiz,
    quiz_history: store.getState().quiz_history,
    preferences: store.getState().preferences
  })
})


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
